import React, {useEffect, useState} from 'react';
import {Button, Dimmer, Divider, Form, Icon, Image, Input, Label, Loader, Message, Modal,} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';

import {isAuthVisible} from '../NavTopBar/reducer';
import {closeAuth} from '../NavTopBar/actions';
import {
    AUTH_SCREEN_AUTH_ENTER_OTP,
    AUTH_SCREEN_MAIN, AUTH_SCREEN_RESET_PWD_ENTER_DATA, AUTH_SCREEN_RESET_PWD_ENTER_OTP, AUTH_SCREEN_SIGNUP,
    authByOTP,
    authGetOTP, clearEmail, clearPhoneNumber,
    fetchToken,
    setAuthScreen,
    setEmail, setPhone, signup
} from './actions';
import './UserLogin.css';

import userLogin, {getAuthFetching, getAuthMode, getData, getUserFetching, getUserLoginError} from './reducer';
import PhoneCountryMask from "../AddressCard/PhoneCountryMask";
import {ADDRESS_PHONE, ADDRESS_PHONE_COUNTRY, fetchCounties, setAddressFields} from "../AddressCard/actions";
import {Link} from "react-router-dom";
import {ReactComponent as AsiaExpoIcon} from '../../icons/asiaexpo2024-icon.svg';
import {getAddressFields, getCountries, isCountriesFetching} from "../AddressCard/reducer";
import _ from "lodash";


import {EmailSuggestions, FioSuggestions, PartySuggestions} from 'react-dadata';

import {toastr} from "react-redux-toastr";
import config from "../../config/config";
import {DADATA_API_KEY} from "../../config/secrets";
import {value} from "lodash/seq";
import {isOnline} from "../WindowDimensions";
import {isMobile} from "react-device-detect";
import {useMixpanel} from "react-mixpanel-browser";


export const cleanMessage = (text) => text? text.replace(/<\/?[^>]+(>|$)/g, "") : "";

export default function UserLogin (props) {

    const {userData } = props;
    const dispatch = useDispatch()
    const mixpanel = useMixpanel()
    const showModal = useSelector((state) => isAuthVisible(state.navtopbar));
    const isFetching = useSelector((state) => getAuthFetching(state.userLogin));
    const data = useSelector((state) => getData(state.userLogin));
    //const isFetchingAuth = useSelector((state) => getAuthFetching(state.userLogin));
    const messageLoginError= useSelector((state) => getUserLoginError(state.userLogin));
    const authMode = useSelector((state) => getAuthMode(state.userLogin));
    const isFetchingCountries = useSelector((state) => isCountriesFetching(state.address));
    const countries = useSelector((state) => getCountries(state.address));

    const defaultSettings = {
        phone: '',
        email: userData && userData.login ? userData.login : '',
        password: '',
        emailError: false,
        passwordError: false,
        showPassword: false,
    };

    const [state, setState] = useState(defaultSettings);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [login, setLogin] = useState("")




    // console.log('UserLogin props & states Debug Mode', authMode, isFetching, phoneNumber);

    const languageString = "ru";
    const urlicon = 'https://cms.asiaexpo.space/wp-content/uploads/2024/08/лого-азия-2025-1.svg'


    let listPhoneCountries = [];

    listPhoneCountries =
        countries.countries ?
            Object.values(countries.countries).map((element, index) => (
                {
                    key:  index,
                    text: languageString === "en" ? element.country_en : element.country_ru,
                    flag: _.lowerCase(element.iso),
                    value: element.iso,
                    className: "dropdown-item",
                    phone_mask: element.phone_mask,
                }
            )) : [] ;

    useEffect(() => {

        // по умолчанию устанавливаем главное окно
        dispatch(setAuthScreen(AUTH_SCREEN_MAIN));

        // если стран нет, то запрашиваем их
        if (!countries || !countries.countries) {
            dispatch(fetchCounties());
        }

    }, []);

    useEffect(() => {

        if (mixpanel)
            mixpanel.track('Login Dialog Open', { ...userData });

        //setState(defaultSettings);
        //setContent(null);

    }, [showModal === true]);

    function validateForm() {
        setState({ ...state, emailError: !state.email.length, passwordError: !state.password.length});
        return (state.email.length && state.password.length);
    }

    function submitForm() {
        if (validateForm())
            dispatch(fetchToken(state.email, state.password, languageString));
        //setContent(null)
    }

    function handleSetShowPassword(show) {
        setState({...state, showPassword: show});
    }

    const handleChangeField = (fieldName, value) => {
        setPhoneNumber(value);
        //dispatch(authPhone(value))
    }



    const ConfirmCode = (props) => {
        // const { mixpanel } = props
        //  console.log(mixpanel)
        const [timeoutRepeat, setTimeoutRepeat] = useState(authMode.data.data.otp_get_timeout);
        const [timeoutWait, setTimeoutWait] = useState(authMode.data.data.otp_wait_timeout);

        const [textColor, setTextColor] = useState("#A7AFB7");
        const [inputs, setInputs] = useState(['', '', '', '']);

        // запускаем таймер ожидания для повторной отправки кода
        useEffect(() => {
            document.getElementById('input-number0').focus();
            let time = timeoutRepeat;
            const intervalId = setInterval(() => {
                time--;
                setTimeoutRepeat(time);
                if (time === 0)
                    clearInterval(intervalId);
            }, 1000);
            return () => clearInterval(intervalId);

        }, []);

        // запускаем таймер ожидания для воода полученного кода
        useEffect(() => {
            let time = timeoutWait;
            const intervalId = setInterval(() => {
                time--;
                setTimeoutWait(time);
                if (time === 0) {
                    clearInterval(intervalId);
                    toastr.error("Время сессии истекло, авторизуйтесь повторно");
                    dispatch(setAuthScreen(AUTH_SCREEN_MAIN));
                }
            }, 1000);
            return () => clearInterval(intervalId);
        }, []);

        function handleKeyPress(e, index) {
            if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'].includes(e.key)) {
                if (e.key === 'Backspace') {
                    if (inputs[index] === '') {
                        if (index > 0) {
                            document.getElementById(`input-number${index - 1}`).focus();
                        }
                    }
                } else {
                    if (inputs.length < 1) {
                        e.preventDefault();
                    }
                }
            } else {
                e.preventDefault();
            }
        }

        const handleClick = () => {
            //setSeconds(authMode.data.otp_get_timeout);
            setTextColor('#A7AFB7');
            dispatch(authGetOTP(languageString, config.APP_CODE, phoneNumber));
        }

        const [error, setError] = useState(false)
        const handleInput = (index, value) => {
            const newInputs = [...inputs];
            newInputs[index] = value;

            if (value && index < inputs.length - 1) {
                document.getElementById(`input-number${index + 1}`).focus();
            } else if (!value && index > 0) {
                document.getElementById(`input-number${index - 1}`).focus();
            }

            setInputs(newInputs);

            const enteredCode = newInputs.join("");
            if (enteredCode.length === 4) {
                dispatch(authByOTP(languageString, config.APP_CODE, phoneNumber, authMode.data.data.id_session, enteredCode))
            }
        }

        const handleReset = () => {
            setInputs(["", "", "", ""])
            document.getElementById('input-number0').focus();
        }

        function testJump(x){
            var ml = ~~x.getAttribute('maxlength');
            if(ml && x.value.length >= ml){
                do{
                    x = x.nextSibling;
                }
                while(x && !(/text/.test(x.type)));
                if(x && /text/.test(x.type)){
                    x.focus();
                }
            }
        }
        return (
            <div className='main-block5'>
                <Image src={urlicon}/>
                <div className='container-numbers'>
                    <span className='text-for-label'>Введите четырехзначный код отправленный на номер</span>
                    <span className='number-phone' id='changePhone'>{phoneNumber}</span>
                </div>
                <button id='btnCode' onClick={handleClick} disabled={timeoutRepeat > 0}>
                <span className='text-again'
                      style={{color: timeoutRepeat === 0 ? 'black' : textColor}}>{timeoutRepeat === 0 ? "Отправить повторно" : "Отправить повторно" + " " + `(${timeoutRepeat}` + " " + "сек" + ")"}</span>
                </button>
                {(error) && <div style={{color: 'red', fontFamily: 'Muller-Bold'}}>Неверный код</div>}
                <div className='container-inputs'>
                    {
                        inputs.map((value, index) => (
                            <input
                                key={index}
                                id={`input-number${index}`}
                                maxLength='1'
                                className='input-number'
                                type={isMobile ? "number" : "text"}
                                autoComplete="off"
                                onInput={e => testJump(e.target)}
                                value={value}
                                onKeyPress={(e) => handleKeyPress(e, index)}
                                onChange={(e) => handleInput(index, e.target.value)}
                            />
                        ))
                    }
                </div>
                <div onClick={() => handleReset()} style={{color: '#A7AFB7', cursor: 'pointer'}}>Очистить поля ввода
                </div>
                <Dimmer active={isFetching} inverted>
                    <Loader active={isFetching}>Загружаем информацию...</Loader>
                </Dimmer>
            </div>
        )
    }

    const handleSendCode = () => {
        if (!phoneNumber && navigator.onLine) {
            toastr.warning("Пожалуйста, введите корректный номер телефона")
        } else {
           // dispatch(setAuthScreen(AUTH_SCREEN_AUTH_ENTER_OTP))
            dispatch(authGetOTP(languageString, config.APP_CODE, phoneNumber));
            if (mixpanel) {
                mixpanel.track('Action. Start Auth by OTP')
            }


        }
    }

    const onClose = () => {
        dispatch(closeAuth())
        dispatch(setAuthScreen(AUTH_SCREEN_MAIN))
    }

    const filteredCountries = listPhoneCountries.filter(country => country.value === "RU");

    return (
        <Modal
            dimmer
            closeIcon
            open={showModal}
            onClose={() => onClose()}
            centered
            closeOnDimmerClick
            className="user-login"
        >
            {authMode.mode === AUTH_SCREEN_AUTH_ENTER_OTP ? <ConfirmCode props={{phoneNumber, login, ...props}}/> : ''}
            {authMode.mode === AUTH_SCREEN_MAIN ?
                <>
                    <div className='main-block3'>
                        <Image src={urlicon} style={{marginTop: '5%'}}/>

                        <span className='text-for-label'>Для входа в приложение введите свой логин и пароль, или авторизуйтесь, используя номер телефона</span>

                        <PhoneCountryMask
                            handleChangeField={handleChangeField}
                            extraClass="shipping-field shipping-phone"
                            countries={filteredCountries}
                            // loading={isMobile ? "" : isFetchingCountries}
                            country="RU"
                            phone={phoneNumber}
                            nameCountry={ADDRESS_PHONE_COUNTRY}
                            namePhone={ADDRESS_PHONE}
                            fluid={false}
                        />

                        <div className='container-button-confirm'>
                            <Link className="button-confirm-code"
                            >
                                <button
                                    className='text-for-link'
                                    onClick={() => handleSendCode()}
                                >Отправить код подтверждения
                                </button>
                            </Link>
                        </div>
                        <div className='container-line'>
                            <div className="line-or"></div>
                            <div className="text-or">ИЛИ</div>
                            <div className="line-or"></div>
                        </div>
                    </div>
                    <Modal.Content>
                        <Modal.Description>
                            {messageLoginError !== '' ? (
                                <Message
                                    negative
                                    icon="user times"
                                    header="Вы не авторизованы"
                                    content={cleanMessage(messageLoginError)}
                                />
                            ) : ''}
                            <Form error={state.emailError || state.passwordError} onSubmit={submitForm}
                                //loading={Boolean(isFetching)}
                            >
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        icon="user"
                                        iconPosition="left"
                                        placeholder="Логин или почта"
                                        onChange={(e) => setState({...state, email: e.target.value})}
                                        error={state.emailError}
                                        //type="email"
                                        name="login"
                                        autoComplete="on"
                                        value={state.email}
                                        id='input-numbers'
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        placeholder="Пароль"
                                        onChange={(e) => setState({...state, password: e.target.value})}
                                        error={state.passwordError}
                                        type={state.showPassword ? "text" : "password"}
                                        name="password"
                                        autoComplete="on"
                                        value={state.password}
                                        iconPosition="left"
                                        labelPosition="right"
                                        id="input-numbers">
                                        <Icon name="key"/>
                                        <input/>
                                        <Label
                                            icon={state.showPassword ? "eye" : "eye slash"}
                                            onClick={() => handleSetShowPassword(!state.showPassword)}
                                            className="user-login-toggle-show-password"
                                            id='eye-icon-id'
                                        />
                                    </Form.Input>
                                </Form.Field>
                                <div className="container-buttons-auth">
                                    <button
                                        disabled={state.email === '' || state.password === ''}
                                        className={state.email === '' || state.password === '' ? "login-form-submit-button-disabled" : "login-form-submit-button"}
                                        onClick={submitForm}

                                    >
                                        Войти
                                    </button>
                                </div>
                                <br/>

                            </Form>
                        </Modal.Description>

                        <Dimmer active={isFetching} inverted>
                            <Loader active={isFetching}>Загружаем информацию...</Loader>
                        </Dimmer>

                    </Modal.Content>
                </> : '' }
        </Modal>


    );
}


