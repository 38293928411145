import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {Loader, Container, Dimmer} from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';


import { closeSearch } from '../../components/NavTopBar/actions';
import { isSearchVisible } from '../../components/NavTopBar/reducer';
import { useParams } from 'react-router';
import {fetchOrders, ORDERS_PAGE_COUNT} from "./actions";
import {getOrders, getOrdersFetching, getOrdersHasMore} from "./reducer";
import {getAuthToken, getUserData} from "../../components/UserLogin/reducer";
import {store} from "../../configureStore";
import OrdersList from "../../components/OrdersList";
import {toastr} from "react-redux-toastr";
import {getLocationData} from "../../components/GeoLocation/reducer";
import {getExpoCurrentLocation, getExpoLocations} from "../../components/ExpoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";

export default function Orders(props) {

  const matchParams = useParams();
  const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));
  const loading = useSelector((state) => getOrdersFetching(state.orders));
  const orders = useSelector((state) => getOrders(state.orders));
  const hasMore = useSelector((state) => getOrdersHasMore(state.orders));
  const token = useSelector((state) => getAuthToken(state.userLogin));
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchVisible) {
      dispatch(closeSearch());
    }
    if (token)
      readOrders(1);
    else
      toastr.error('Для получения доступа к списку, пожалуйста, авторизуйтесь и войдите в систему');
    window.scrollTo(0, 0);
  }, []);

  const userData  = useSelector((state) => getUserData(state.userLogin));
  const userLocation  = useSelector((state) => getLocationData(state.userLocation));
  const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
  const mixpanel = useMixpanel();
  useEffect(() => {
    if (mixpanel)
        mixpanel.track('Orders List View', { ...currentExpoLocation, ...userLocation, ...userData });
  }, []);

  const loadOrders = () => {
    if (hasMore) {
      const nextPage = Math.round(orders.length / ORDERS_PAGE_COUNT) + 1;
      readOrders(nextPage);
    }
  };

  const readOrders = (page) => {
    dispatch(fetchOrders(token, {
      page,
      order: 'desc',
      orderby: 'id',
      per_page: ORDERS_PAGE_COUNT,
    }));
  };

  if (loading === 1 && orders.length === 0) {
    return (
        <Dimmer active={ loading } inverted>
          <Loader>Загружаем заказы ...</Loader>
        </Dimmer>
    );
  }

  if (orders.length === 0) {
    return ( <Container><p>Заказы не найдены.</p></Container> );
  }

  return (
      <>
          <InfiniteScroll
              dataLength={orders.length}
              next={loadOrders}
              hasMore={hasMore}
              loader={<h3 className="scroll-loader-wraploadingper">Загрузка ...</h3>}
              endMessage={(
                  <p style={{ textAlign: 'center' }}>
                      <b>Конец списка</b>
                  </p>
              )}
          >
              <OrdersList
                  orders={_.orderBy(orders, ['id'], ['desc'])}
                  title={(
                      <>
                          <span className='title-text-ticket'>Посетители</span>
                      </>
                  )}
              />
          </InfiniteScroll>
      </>

  );

}

