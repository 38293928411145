import React, {useEffect} from 'react';

import {fetchProducts, PAGE_COUNT} from './actions';

import ProductsList from '../../components/ProductsList';

import {useDispatch, useSelector} from "react-redux";
import {getProducts, getProductsFetching, getProductsHasMore} from "./reducer";
import InfiniteView from "../../components/InfiniteView";
import {setWindowScrollPosition} from "../../components/WindowDimensions";
import {getAuthToken, getUserData, getUserLoggedIn} from "../../components/UserLogin/reducer";
import {getLocationData} from "../../components/GeoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";
import config from "../../config/config";
import './style.css'

export default function Home (props) {

    const loading = useSelector((state) => getProductsFetching(state.products));
    const products = useSelector((state) => getProducts(state.products));
    const hasMore = useSelector((state) => getProductsHasMore(state.products));
    const token = useSelector((state) => getAuthToken(state.userLogin));
    const dispatch = useDispatch();
    const userData = useSelector((state) => getUserData(state.userLogin));
    const userLocation = useSelector((state) => getLocationData(state.userLocation));
    const isLoggedIn = useSelector((state) => getUserLoggedIn(state.userLogin));
    const mixpanel = useMixpanel();

    useEffect(() => {
        readProducts(1);
        setWindowScrollPosition();

        if (mixpanel)
            mixpanel.track('Products List View', { ...userLocation, ...userData });
    }, []);

     function  loadProducts() {
       const nextPage = Math.round(products.length / PAGE_COUNT) + 1;
       readProducts(nextPage);
      }

      function readProducts(page) {
        if (token)
            dispatch(fetchProducts({
              services: 'yes',
              page,
              order: 'asc',
              orderby: 'title',
              per_page: PAGE_COUNT,
              category: config.CATALOG_CATEGORIES_ID
        }, token));
      }

      return (
          <>
              <InfiniteView
                  dataLength={products.length}
                  nextFetch={loadProducts}
                  hasMore={hasMore}
                  dataList={(
                      <ProductsList products={products} title={(
                          <span className='title-text-ticket'>Билеты на мероприятие</span>
                      )} {...props}/>
                  )}
                  mustAuth={true}
                  isAuth={!!token}
                  isLoading={loading.fetching}
                  loaderText="Загружаем билеты ..."
                  zeroText="Билеты на мероприятия отсутствуют"
              />
          </>

    );
}
